import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { 
  isSignInWithEmailLink, 
  signInWithEmailLink,
  updatePassword,
  signInWithEmailAndPassword
} from 'firebase/auth';
import { auth } from '../firebase';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';

function EmailVerification() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState('');
  const [isProcessing, setIsProcessing] = useState(true);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [needsPassword, setNeedsPassword] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    const handleEmailLink = async () => {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        let emailFromStorage = window.localStorage.getItem('emailForSignIn');
        let emailFromUrl = searchParams.get('email');
        
        // Try email from URL first, then fallback to localStorage
        const emailToUse = emailFromUrl || emailFromStorage;
        
        if (!emailToUse) {
          setError('No email found. Please try the verification link again.');
          setIsProcessing(false);
          return;
        }

        try {
          // Sign in with email link
          await signInWithEmailLink(auth, emailToUse, window.location.href);
          
          // After successful verification, show password setup
          setEmail(emailToUse);
          setNeedsPassword(true);
          setIsProcessing(false);
        } catch (error) {
          console.error('Error signing in with email link:', error);
          setError('Failed to verify email link. Please try again.');
          setIsProcessing(false);
        }
      } else {
        navigate('/signin');
      }
    };

    handleEmailLink();
  }, [navigate, searchParams]);

  const handleSetPassword = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    if (password.length < 6) {
      setError('Password must be at least 6 characters');
      return;
    }

    setIsProcessing(true);
    try {
      // Set the password
      await updatePassword(auth.currentUser, password);
      
      // Create user document
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      await setDoc(userDocRef, {
        email: email,
        timestamp: new Date(),
        verified: true
      });

      // Clear the stored email
      window.localStorage.removeItem('emailForSignIn');

      // Sign in with the new credentials to ensure everything works
      await signInWithEmailAndPassword(auth, email, password);

      // Navigate to report page
      navigate('/report');
    } catch (error) {
      console.error('Failed to set password:', error);
      setError('Failed to set password. Please try again.');
      setIsProcessing(false);
    }
  };

  if (isProcessing && !needsPassword) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-900 to-blue-800">
        <div className="text-white text-center">
          <h2 className="text-2xl font-bold mb-4">Verifying your email...</h2>
          <p className="text-lg opacity-80">Please wait while we complete the verification.</p>
        </div>
      </div>
    );
  }

  if (needsPassword) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-900 to-blue-800">
        <div className="bg-white/95 rounded-xl shadow-lg p-8 max-w-md w-full backdrop-blur-md">
          <h2 className="text-2xl font-bold text-gray-900 mb-6 text-center">Set Your Password</h2>
          <p className="text-gray-600 mb-6 text-center">
            Your email has been verified! Please set a password for your account: <br/>
            <span className="font-medium text-blue-600">{email}</span>
          </p>
          
          <form onSubmit={handleSetPassword} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Password
              </label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter your password"
                required
                minLength={6}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Confirm Password
              </label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Confirm your password"
                required
                minLength={6}
              />
            </div>

            {error && (
              <p className="text-red-600 text-sm">{error}</p>
            )}

            <button
              type="submit"
              disabled={isProcessing}
              className={`w-full py-3 px-4 rounded-md text-white font-medium
                ${isProcessing 
                  ? 'bg-blue-400 cursor-not-allowed' 
                  : 'bg-blue-600 hover:bg-blue-700'
                } transition-colors`}
            >
              {isProcessing ? 'Setting Password...' : 'Set Password & Continue'}
            </button>
          </form>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-900 to-blue-800">
        <div className="bg-white/10 backdrop-blur-md rounded-lg p-8 max-w-md mx-auto text-center">
          <p className="text-red-400 text-xl mb-4">{error}</p>
          <button
            onClick={() => navigate('/signin')}
            className="px-6 py-2 bg-white text-blue-900 rounded-md hover:bg-white/90 transition-colors"
          >
            Back to Sign In
          </button>
        </div>
      </div>
    );
  }

  return null;
}

export default EmailVerification; 