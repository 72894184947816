import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Link, useNavigate } from 'react-router-dom';
import { sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { auth, db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';

// Styled components for the background
const PageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;
  overflow: hidden;
  background: linear-gradient(45deg, #1a237e, #0d47a1);

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 1000px;
    height: 1000px;
    border-radius: 40%;
    background: linear-gradient(45deg, rgba(66, 165, 245, 0.2), rgba(21, 101, 192, 0.2));
    animation: rotate 20s linear infinite;
  }

  &::before {
    top: -30%;
    left: -10%;
    animation-delay: -5s;
  }

  &::after {
    bottom: -30%;
    right: -10%;
    animation-duration: 25s;
  }
`;

const AnimatedBubble = styled.div`
  position: absolute;
  background: radial-gradient(circle at center, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%);
  border-radius: 50%;
  pointer-events: none;
  
  &:nth-of-type(1) {
    width: 400px;
    height: 400px;
    top: 10%;
    left: 20%;
    animation: float 15s ease-in-out infinite;
  }

  &:nth-of-type(2) {
    width: 300px;
    height: 300px;
    top: 60%;
    right: 20%;
    animation: float 18s ease-in-out infinite -2s;
  }

  &:nth-of-type(3) {
    width: 200px;
    height: 200px;
    top: 30%;
    right: 30%;
    animation: float 12s ease-in-out infinite -5s;
  }
`;

function Uncover() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    agreements: false,
  });
  const [errors, setErrors] = useState({});
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isProcessingAuth, setIsProcessingAuth] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [sentToEmail, setSentToEmail] = useState('');

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setFormData(prev => ({
      ...prev,
      email: value
    }));
    
    if (value.trim() === '') {
      setErrors(prev => ({ ...prev, email: 'Email is required' }));
      setIsValidEmail(false);
    } else if (!validateEmail(value)) {
      setErrors(prev => ({ ...prev, email: 'Please enter a valid email' }));
      setIsValidEmail(false);
    } else {
      setErrors(prev => ({ ...prev, email: '' }));
      setIsValidEmail(true);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === 'email') {
      handleEmailChange(e);
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      }));
      if (errors[name]) {
        setErrors(prev => ({ ...prev, [name]: '' }));
      }
    }
  };

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!validateEmail(formData.email)) {
      newErrors.email = 'Please enter a valid email';
    }
    
    if (!formData.agreements) {
      newErrors.agreements = 'You must agree to the terms and newsletter subscription';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    // Check if the user is coming from an email link
    if (isSignInWithEmailLink(auth, window.location.href)) {
      const email = window.localStorage.getItem('emailForSignIn');
      if (email) {
        setIsProcessingAuth(true);
        signInWithEmailLink(auth, email, window.location.href)
          .then(() => {
            window.localStorage.removeItem('emailForSignIn');
            setEmailSent(true);
            setSentToEmail(email);
          })
          .catch((error) => {
            console.error('Error signing in with email link:', error);
            setErrors(prev => ({
              ...prev,
              submit: 'Failed to verify email link. Please try again.'
            }));
          })
          .finally(() => {
            setIsProcessingAuth(false);
          });
      }
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsProcessingAuth(true);
      try {
        // Store email in localStorage for verification process
        window.localStorage.setItem('emailForSignIn', formData.email);

        // Add to pendingUsers collection
        const pendingUserRef = collection(db, 'pendingUsers');
        await addDoc(pendingUserRef, {
          email: formData.email,
          timestamp: new Date(),
          status: 'pending',
          verificationUrl: 'https://uncoveragent.com/verify-email'
        });

        setEmailSent(true);
        setSentToEmail(formData.email);
        setFormData(prev => ({ ...prev, email: '' }));
      } catch (error) {
        console.error('Error:', error);
        setErrors(prev => ({
          ...prev,
          submit: 'An error occurred. Please try again.'
        }));
      }
      setIsProcessingAuth(false);
    }
  };

  if (emailSent) {
    return (
      <PageContainer>
        <div className="flex max-w-7xl w-full bg-white/95 rounded-xl overflow-hidden shadow-lg relative z-10 backdrop-blur-md p-10 justify-center items-center">
          <div className="text-center">
            <h2 className="text-2xl font-bold mb-4">Report Request Received</h2>
            <p className="text-gray-600 mb-4">
              We've received your registration request for <span className="text-blue-600 font-medium">{sentToEmail}</span>
            </p>
            <p className="text-gray-500 mb-6">
              Our team will review your request and send you a verification email shortly.
            </p>
            <p className="text-sm text-gray-400 font-bold">
              Please make sure to check your spam folder if you don't see our email in your inbox.
            </p>
          </div>
        </div>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <AnimatedBubble />
      <AnimatedBubble />
      <AnimatedBubble />
      
      <div className="flex max-w-7xl w-full bg-white/95 rounded-xl overflow-hidden shadow-lg relative z-10 backdrop-blur-md md:flex-row flex-col">
        <div className="flex-1 bg-blue-600 text-white p-10 flex flex-col justify-center md:text-left text-center">
          <h1 className="text-4xl mb-4 font-bold">
            Want to uncover your published Microsoft Copilot-Studio AI agents?
          </h1>
          <p className="text-lg leading-relaxed">
            Get your scanning report now. Discover potential security risks and optimize your agent deployments.
          </p>
        </div>

        <div className="flex-1 p-10 flex flex-col justify-center items-center">
          {emailSent ? (
            <div className="text-center">
              <h2 className="text-2xl font-bold mb-4">Email Sent!</h2>
              <p className="text-gray-600 mb-2">
                For your report, we've sent a login link to:
              </p>
              <p className="text-blue-600 font-medium mb-4">{sentToEmail}</p>
              <p className="text-sm text-gray-500">
                Please check your email and click the link to access your report.
                Don't forget to check your spam folder if you don't see it.
              </p>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="w-full max-w-md">
              <h2 className="text-3xl mb-4 text-center font-bold">Uncover Your Agents</h2>
              
              <p className="text-gray-600 text-sm mb-4 text-center">
                Use your organization's email to scan the correct domain for your Copilot Studio agents
              </p>
              
              <div className="mb-4">
                <input
                  type="email"
                  name="email"
                  placeholder="Organization email"
                  value={formData.email}
                  onChange={handleChange}
                  className={`w-full p-3 border-2 rounded-md bg-gray-50 outline-none transition-colors
                    ${errors.email ? 'border-red-500' : isValidEmail && formData.email.length > 0 ? 'border-green-500' : 'border-gray-300'}
                    focus:border-blue-600`}
                />
                {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
              </div>
              
              <div className="mb-6">
                <label className="flex gap-2 text-sm text-gray-600">
                  <input
                    type="checkbox"
                    name="agreements"
                    checked={formData.agreements}
                    onChange={handleChange}
                    required
                    className="mt-1"
                  />
                  <span>
                    I agree to the <Link to="/terms" className="text-blue-600 hover:underline">Terms of Service</Link>, 
                    Privacy Policy and subscribe to updates
                  </span>
                </label>
                {errors.agreements && <p className="text-red-500 text-sm mt-1">{errors.agreements}</p>}
              </div>
              
              <button
                type="submit"
                disabled={isProcessingAuth}
                className={`w-full py-3 bg-blue-600 text-white rounded-md transition-colors
                  ${isProcessingAuth ? 'opacity-70 cursor-not-allowed' : 'hover:bg-blue-700'}`}
              >
                {isProcessingAuth ? 'Processing...' : 'Get Me to the Report'}
              </button>

              <div className="text-center mt-6">
                <p className="text-gray-600">
                  Already have an account? <Link to="/signin" className="text-blue-600 hover:underline">Sign in</Link>
                </p>
              </div>
            </form>
          )}
        </div>
      </div>
    </PageContainer>
  );
}

export default Uncover; 