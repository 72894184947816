import React, { useState, useEffect } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import { auth, db } from '../firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { FiLogOut, FiExternalLink } from 'react-icons/fi';
import { SiAmazon, SiLangchain } from 'react-icons/si';
import copilotIcon from '../assets/copilot-icon.png';
import flowiseIcon from '../assets/flowise.png';
import azureFoundryIcon from '../assets/azure-foundry.png';
import vertexAiIcon from '../assets/vertex-ai.png';
import difyIcon from '../assets/dify.png';
import agentforceIcon from '../assets/agentforce.png';

function Flowise() {
  const navigate = useNavigate();
  const [url, setUrl] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [userId, setUserId] = useState(null);
  const [success, setSuccess] = useState(false);
  const [flowiseStatus, setFlowiseStatus] = useState(null);
  const [hasExistingUrl, setHasExistingUrl] = useState(false);

  const navigationItems = [
    {
      name: 'Copilot Studio',
      icon: copilotIcon,
      isImage: true,
      active: window.location.pathname === '/report',
      link: '/report'
    },
    {
      name: 'Flowise',
      icon: flowiseIcon,
      isImage: true,
      active: window.location.pathname === '/flowise',
      link: '/flowise'
    },
    {
      name: 'Amazon Bedrock',
      icon: SiAmazon,
      disabled: true
    },
    {
      name: 'LangChain',
      icon: SiLangchain,
      disabled: true
    },
    {
      name: 'AgentForce',
      icon: agentforceIcon,
      isImage: true,
      disabled: true
    },
    {
      name: 'Vertex AI',
      icon: vertexAiIcon,
      disabled: true,
      isImage: true
    },
    {
      name: 'Azure AI Foundry',
      icon: azureFoundryIcon,
      isImage: true,
      disabled: true
    },
    {
      name: 'Dify',
      icon: difyIcon,
      isImage: true,
      disabled: true
    },
  ];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/signin');
        return;
      }
      setUserEmail(user.email);
      setUserId(user.uid);

      // Load existing Flowise URL and status if it exists
      const loadFlowiseData = async () => {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            const data = userDoc.data();
            if (data.flowiseUrl) {
              setUrl(data.flowiseUrl);
              setHasExistingUrl(true);
            }
            if ('flowiseStatus' in data) {
              setFlowiseStatus(data.flowiseStatus);
            }
          }
        } catch (error) {
          console.error('Error loading Flowise data:', error);
        }
      };
      
      loadFlowiseData();
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/signin');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');
    setSuccess(false);

    try {
      if (!userId) {
        throw new Error('User not authenticated');
      }

      // Validate URL format
      try {
        new URL(url);
      } catch (urlError) {
        throw new Error('Please enter a valid URL');
      }

      // Get user document reference
      const userDocRef = doc(db, 'users', userId);
      
      // Get current user data
      const userDoc = await getDoc(userDocRef);
      
      // Update or create user document with Flowise URL
      await setDoc(userDocRef, {
        ...userDoc.exists() ? userDoc.data() : {},
        flowiseUrl: url,
        flowiseLastUpdated: new Date()
      }, { merge: true });

      setSuccess(true);
      console.log('Flowise URL saved successfully:', url);
    } catch (error) {
      console.error('Error saving Flowise URL:', error);
      setError(error.message || 'Failed to save URL. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const getStatusDisplay = () => {
    if (flowiseStatus === null) {
      return (
        <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
          Test Pending
        </span>
      );
    } else if (flowiseStatus === true) {
      return (
        <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
          Patched
        </span>
      );
    } else {
      return (
        <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-red-100 text-red-800">
          Not Patched
        </span>
      );
    }
  };

  const MainContent = () => (
    <div className="bg-white rounded-lg shadow-sm p-8 m-6">
      <h2 className="text-xl font-semibold text-gray-900 mb-4">Flowise Security Check</h2>
      <div className="space-y-4">
        {hasExistingUrl ? (
          <div className="space-y-4">
            <div className="flex items-center space-x-4">
              <p className="text-gray-600">Your Flowise URL: </p>
              <a 
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800 underline"
              >
                {url}
              </a>
            </div>
            <div className="flex items-center space-x-4">
              <p className="text-gray-600">Status: </p>
              {getStatusDisplay()}
            </div>
          </div>
        ) : (
          <>
            <p className="text-gray-600">
              To check if you've applied the{' '}
              <a 
                href="https://github.com/dorattias/CVE-2025-26319/blob/main/CVE-2025-26319-FIX.patch" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800 underline"
              >
                security patch
              </a>{' '}
              and your Flowise instance is exposed to the web, please provide the URL to your site:
            </p>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <input
                  type="text"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  placeholder="https://your-flowise-instance.com"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  required
                  pattern="https?://.*"
                  title="Please enter a valid URL starting with http:// or https://"
                />
                {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
                {success && (
                  <p className="mt-2 text-sm text-green-600">
                    URL saved successfully! We'll check your Flowise instance for security vulnerabilities.
                  </p>
                )}
              </div>

              <button
                type="submit"
                disabled={isSubmitting}
                className={`px-4 py-2 rounded-md text-white font-medium
                  ${isSubmitting 
                    ? 'bg-blue-400 cursor-not-allowed' 
                    : 'bg-blue-600 hover:bg-blue-700'
                  } transition-colors`}
              >
                {isSubmitting ? 'Saving...' : 'Save & Check Security'}
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );

  return (
    <div className="min-h-screen flex bg-white">
      {/* Left Sidebar */}
      <div className="w-64 border-r flex flex-col py-4">
        <div className="px-6 mb-8">
          <Link to="/report" className="inline-block">
            <h1 className="text-lg font-semibold text-blue-600">Uncover Agent</h1>
          </Link>
        </div>
        <nav className="flex-1">
          {navigationItems.map((item) => (
            item.link ? (
              <Link
                key={item.name}
                to={item.link}
                className={`w-full px-6 py-2 text-sm text-left 
                  ${item.active 
                    ? 'text-gray-700 bg-gray-50 border-l-2 border-blue-600' 
                    : 'text-gray-400 hover:bg-gray-50 border-l-2 border-transparent'}
                  flex items-center group transition-colors
                  ${item.disabled && 'opacity-60 pointer-events-none'}`}
              >
                {item.isImage ? (
                  <img 
                    src={item.icon}
                    alt={item.name}
                    className="w-4 h-4 mr-2"
                  />
                ) : (
                  <item.icon className="w-4 h-4 mr-2" />
                )}
                {item.name}
              </Link>
            ) : (
              <button
                key={item.name}
                disabled={item.disabled}
                title={item.disabled ? "Coming Soon" : ""}
                className={`w-full px-6 py-2 text-sm text-left 
                  ${item.active 
                    ? 'text-gray-700 bg-gray-50 border-l-2 border-blue-600' 
                    : 'text-gray-400 hover:bg-gray-50 border-l-2 border-transparent'}
                  flex items-center group transition-colors
                  ${item.disabled && 'opacity-60'}`}
              >
                {item.isImage ? (
                  <img 
                    src={item.icon}
                    alt={item.name}
                    className="w-4 h-4 mr-2"
                  />
                ) : (
                  <item.icon className="w-4 h-4 mr-2" />
                )}
                {item.name}
              </button>
            )
          ))}
        </nav>
      </div>

      <div className="flex-1 flex flex-col">
        {/* Top Navigation Bar */}
        <div className="h-14 border-b px-4 flex items-center justify-end">
          {/* User Menu */}
          <div className="relative">
            <button
              onClick={() => setShowUserMenu(!showUserMenu)}
              className="w-7 h-7 rounded-full bg-blue-600 text-white text-xs flex items-center justify-center"
            >
              {userEmail.charAt(0).toUpperCase()}
            </button>
            
            {showUserMenu && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10 border">
                <div className="px-4 py-2 text-xs text-gray-700 border-b">
                  {userEmail}
                </div>
                <button
                  onClick={handleLogout}
                  className="w-full text-left px-4 py-2 text-xs text-gray-700 hover:bg-gray-100 flex items-center"
                >
                  <FiLogOut className="w-3 h-3 mr-2" />
                  Sign out
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Main Content Area */}
        <div className="flex-1 bg-gray-50 p-6">
          <MainContent />
        </div>
      </div>
    </div>
  );
}

export default Flowise; 