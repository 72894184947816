import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import { db, auth } from '../firebase';
import { FiLogOut, FiExternalLink } from 'react-icons/fi';
import { SiAmazon, SiLangchain, SiGooglecloud } from 'react-icons/si';
import copilotIcon from '../assets/copilot-icon.png';
import flowiseIcon from '../assets/flowise.png';
import azureFoundryIcon from '../assets/azure-foundry.png';
import vertexAiIcon from '../assets/vertex-ai.png';
import difyIcon from '../assets/dify.png';
import agentforceIcon from '../assets/agentforce.png';
import { is } from '@babel/types';

function Report() {
  const navigate = useNavigate();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const [showUserMenu, setShowUserMenu] = useState(false);

  const navigationItems = [
    {
      name: 'Copilot Studio',
      icon: copilotIcon,
      isImage: true,
      active: window.location.pathname === '/report'
    },
    {
      name: 'Flowise',
      icon: flowiseIcon,
      isImage: true,
      active: window.location.pathname === '/flowise',
      link: '/flowise'
    },
    {
      name: 'Amazon Bedrock',
      icon: SiAmazon,
      disabled: true
    },
    {
      name: 'LangChain',
      icon: SiLangchain,
      disabled: true
    },
    {
      name: 'AgentForce',
      icon: agentforceIcon,
      isImage: true,
      disabled: true
    },
    {
      name: 'Vertex AI',
      icon: vertexAiIcon,
      disabled: true,
      isImage: true
    },
    {
      name: 'Azure AI Foundry',
      icon: azureFoundryIcon,
      isImage: true,
      disabled: true
    },
    {
      name: 'Dify',
      icon: difyIcon,
      isImage: true,
      disabled: true
    },
  ];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        navigate('/signin');
        return;
      }

      setUserEmail(user.email);

      try {
        // Get reports subcollection reference
        const reportsRef = collection(db, `users/${user.uid}/reports`);
        const reportsSnapshot = await getDocs(reportsRef);
        
        const userReports = [];
        reportsSnapshot.forEach((doc) => {
          userReports.push({ id: doc.id, ...doc.data() });
        });
        setReports(userReports);
      } catch (err) {
        console.error('Error fetching reports:', {
          error: err.message,
          code: err.code,
          userId: user.uid
        });
        setError('Failed to load reports');
      } finally {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/signin');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const MainContent = () => {
    if (loading) {
      return (
        <div className="flex items-center justify-center h-full">
          <div className="text-gray-600 text-center">
            <p className="text-sm">Loading your reports...</p>
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="flex items-center justify-center h-full">
          <div className="text-center">
            <p className="text-sm text-red-500">{error}</p>
          </div>
        </div>
      );
    }

    // Check if user is marked as no-findings
    const userDoc = reports.find(report => report.status === 'no-findings');
    if (userDoc) {
      return (
        <div className="bg-white rounded-lg shadow-sm p-8 m-6">
          <h2 className="text-xl font-semibold text-gray-900 mb-4">Initial Scan Results</h2>
          <div className="space-y-4">
            <p className="text-gray-600">
              We've completed our initial external scan of your organization's public endpoints and haven't detected any publicly accessible Copilot Studio agents.
            </p>
            <div className="bg-blue-50 border-l-4 border-blue-400 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm text-blue-700">
                    <strong>Important Note:</strong> This doesn't necessarily mean there are no public agents in your organization.
                  </p>
                </div>
              </div>
            </div>
            <p className="text-gray-600">
              While we continue running our comprehensive scan, you might want to consider an internal scan for complete visibility. An internal scan can:
            </p>
            <ul className="list-disc pl-5 text-gray-600 space-y-2">
              <li>Detect agents that might not be visible from external scanning</li>
              <li>Provide a more detailed analysis of your Copilot Studio deployment</li>
              <li>Help identify potential security configurations to review</li>
            </ul>
            <div className="mt-6 bg-gray-50 border border-gray-200 rounded-lg p-4">
              <p className="text-gray-700">
                For a complete assessment of your organization's Copilot Studio agents, please contact us at{' '}
                <a href="mailto:support@uncoveragent.com" className="text-blue-600 hover:text-blue-800 font-medium">
                  support@uncoveragent.com
                </a>
                . Our team will guide you through setting up an internal scan for comprehensive visibility.
              </p>
            </div>
          </div>
        </div>
      );
    }

    if (reports.length === 0) {
      return (
        <div className="bg-white rounded-lg shadow-sm p-6 m-6">
          <h2 className="text-lg font-semibold mb-3">Scan in Progress</h2>
          <div className="flex items-center space-x-3 mb-4">
            <p className="text-sm text-gray-600">
              We're currently scanning your Copilot Studio agents
            </p>
          </div>
          <p className="text-xs text-gray-500">
            This process typically takes a while.
            We will notify you via email when the scan is complete.
          </p>
        </div>
      );
    }

    return (
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Authentication Required
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Risk Score
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Link
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {reports.map(report => (
              <tr key={report.id} className="hover:bg-gray-50 transition-colors">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {report.name || 'Unnamed Agent'}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-3 py-1 rounded-full text-xs font-medium
                    ${report.authenticationRequired === null 
                      ? 'bg-gray-100 text-gray-800' 
                      : report.authenticationRequired 
                        ? 'bg-green-100 text-green-800' 
                        : 'bg-red-100 text-red-800'
                    }`}>
                    {report.authenticationRequired === null 
                      ? 'Pending' 
                      : report.authenticationRequired ? 'Required' : 'Not Required'}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className="text-sm text-gray-600">
                    {report.status || 'Active'}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-3 py-1 rounded-full text-xs font-medium
                    ${report.riskLevel === 'high' 
                      ? 'bg-red-100 text-red-800'
                      : report.riskLevel === 'medium'
                        ? 'bg-yellow-100 text-yellow-800'
                        : 'bg-green-100 text-green-800'
                    }`}>
                    {report.riskLevel || 'Low'}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {report.url ? (
                    <a 
                      href={report.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-800 flex items-center"
                    >
                      View <FiExternalLink className="ml-1 w-4 h-4" />
                    </a>
                  ) : (
                    <span>Not available</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="min-h-screen flex bg-white">
      {/* Left Sidebar */}
      <div className="w-64 border-r flex flex-col py-4">
        <div className="px-6 mb-8">
          <Link to="/report" className="inline-block">
            <h1 className="text-lg font-semibold text-blue-600">Uncover Agent</h1>
          </Link>
        </div>
        <nav className="flex-1">
          {navigationItems.map((item) => (
            item.link ? (
              <Link
                key={item.name}
                to={item.link}
                className={`w-full px-6 py-2 text-sm text-left 
                  ${item.active 
                    ? 'text-gray-700 bg-gray-50 border-l-2 border-blue-600' 
                    : 'text-gray-400 hover:bg-gray-50 border-l-2 border-transparent'}
                  flex items-center group transition-colors
                  ${item.disabled && 'opacity-60 pointer-events-none'}`}
              >
                {item.isImage ? (
                  <img 
                    src={item.icon}
                    alt={item.name}
                    className="w-4 h-4 mr-2"
                  />
                ) : (
                  <item.icon className="w-4 h-4 mr-2" />
                )}
                {item.name}
              </Link>
            ) : (
              <button
                key={item.name}
                disabled={item.disabled}
                title={item.disabled ? "Coming Soon" : ""}
                className={`w-full px-6 py-2 text-sm text-left 
                  ${item.active 
                    ? 'text-gray-700 bg-gray-50 border-l-2 border-blue-600' 
                    : 'text-gray-400 hover:bg-gray-50 border-l-2 border-transparent'}
                  flex items-center group transition-colors
                  ${item.disabled && 'opacity-60'}`}
              >
                {item.isImage ? (
                  <img 
                    src={item.icon}
                    alt={item.name}
                    className="w-4 h-4 mr-2"
                  />
                ) : (
                  <item.icon className="w-4 h-4 mr-2" />
                )}
                {item.name}
              </button>
            )
          ))}
        </nav>
      </div>

      <div className="flex-1 flex flex-col">
        {/* Top Navigation Bar */}
        <div className="h-14 border-b px-4 flex items-center justify-end">
          {/* User Menu */}
          <div className="relative">
            <button
              onClick={() => setShowUserMenu(!showUserMenu)}
              className="w-7 h-7 rounded-full bg-blue-600 text-white text-xs flex items-center justify-center"
            >
              {userEmail.charAt(0).toUpperCase()}
            </button>
            
            {showUserMenu && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10 border">
                <div className="px-4 py-2 text-xs text-gray-700 border-b">
                  {userEmail}
                </div>
                <button
                  onClick={handleLogout}
                  className="w-full text-left px-4 py-2 text-xs text-gray-700 hover:bg-gray-100 flex items-center"
                >
                  <FiLogOut className="w-3 h-3 mr-2" />
                  Sign out
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Main Content Area */}
        <div className="flex-1 bg-gray-50 p-6">
          <MainContent />
        </div>
      </div>
    </div>
  );
}

export default Report; 