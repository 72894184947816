import React, { useState } from 'react';
import styled from '@emotion/styled';
import { sendSignInLinkToEmail } from 'firebase/auth';
import { auth, db } from '../firebase';
import { Link } from 'react-router-dom';

// Reuse the same background components
const PageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;
  overflow: hidden;
  background: linear-gradient(45deg, #1a237e, #0d47a1);

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 1000px;
    height: 1000px;
    border-radius: 40%;
    background: linear-gradient(45deg, rgba(66, 165, 245, 0.2), rgba(21, 101, 192, 0.2));
    animation: rotate 20s linear infinite;
  }

  &::before {
    top: -30%;
    left: -10%;
    animation-delay: -5s;
  }

  &::after {
    bottom: -30%;
    right: -10%;
    animation-duration: 25s;
  }
`;

const AnimatedBubble = styled.div`
  position: absolute;
  background: radial-gradient(circle at center, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%);
  border-radius: 50%;
  pointer-events: none;
  
  &:nth-of-type(1) {
    width: 400px;
    height: 400px;
    top: 10%;
    left: 20%;
    animation: float 15s ease-in-out infinite;
  }

  &:nth-of-type(2) {
    width: 300px;
    height: 300px;
    top: 60%;
    right: 20%;
    animation: float 18s ease-in-out infinite -2s;
  }

  &:nth-of-type(3) {
    width: 200px;
    height: 200px;
    top: 30%;
    right: 30%;
    animation: float 12s ease-in-out infinite -5s;
  }
`;

// eslint-disable-next-line no-unused-vars
const LogoIcon = () => (
  <svg 
    width="40" 
    height="40" 
    viewBox="0 0 40 40" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* Main circle with gradient */}
    <defs>
      <radialGradient
        id="golfBallGradient"
        cx="30%"
        cy="30%"
        r="70%"
        fx="25%"
        fy="25%"
      >
        <stop offset="0%" stopColor="white" />
        <stop offset="100%" stopColor="#e0e0e0" />
      </radialGradient>
      
      {/* Pattern for golf ball dimples */}
      <pattern
        id="dimplePattern"
        x="0"
        y="0"
        width="10"
        height="10"
        patternUnits="userSpaceOnUse"
      >
        <circle
          cx="5"
          cy="5"
          r="1"
          fill="rgba(0,0,0,0.1)"
        />
      </pattern>
    </defs>

    {/* Base circle with 3D effect */}
    <circle
      cx="20"
      cy="20"
      r="18"
      fill="url(#golfBallGradient)"
      stroke="rgba(255,255,255,0.8)"
      strokeWidth="1"
    />

    {/* Dimple pattern overlay */}
    <circle
      cx="20"
      cy="20"
      r="18"
      fill="url(#dimplePattern)"
      fillOpacity="0.7"
    />

    {/* Highlight */}
    <path
      d="M15 12C15 12 18 10 22 12C26 14 28 18 28 18"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      opacity="0.7"
    />
  </svg>
);

const Logo = styled.div`
  font-size: 2rem;
  font-weight: bold;
  color: white;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;

  svg {
    filter: drop-shadow(0px 2px 4px rgba(0,0,0,0.2));
  }
`;

const UnselectableText = styled.div`
  user-select: none;
`;

function Home() {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    try {
      // Firebase email link configuration
      const actionCodeSettings = {
        url: window.location.origin + '/email-confirmation',
        handleCodeInApp: true,
      };

      // Send sign-in link
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      
      // Save email to localStorage for verification
      window.localStorage.setItem('emailForSignIn', email);
      
      setIsSubmitted(true);
      setEmail('');
    } catch (error) {
      console.error('Error sending email link:', error);
      setError('Failed to send login link. Please try again.');
    }
  };

  return (
    <PageContainer>
      <AnimatedBubble />
      <AnimatedBubble />
      <AnimatedBubble />
      
      <div className="relative z-10 text-center">
        <UnselectableText>
          <h1 className="text-white text-8xl font-bold mb-4 tracking-tight">
            Coming Soon
          </h1>
          
          <Logo>
            {/* <LogoIcon /> */}
            <span>Swing Security</span>
          </Logo>
        </UnselectableText>
        
        <div className="bg-white/10 backdrop-blur-md rounded-lg p-8 max-w-md mx-auto mt-12">
          {!isSubmitted ? (
            <form onSubmit={handleSubmit} className="space-y-4">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Organization Email"
                required
                className="w-full px-4 py-2 rounded-md border border-white/20 bg-white/10 text-white placeholder-white/50 focus:outline-none focus:border-white"
              />
              {error && (
                <p className="text-red-400 text-sm">{error}</p>
              )}
              <button
                type="submit"
                className="w-full px-4 py-2 bg-white text-blue-900 rounded-md font-semibold hover:bg-white/90 transition-colors"
              >
                Stay Updated
              </button>
            </form>
          ) : (
            <div className="text-white">
              <p className="text-lg mb-2">Thank you for subscribing!</p>
              <p className="text-sm opacity-80">
                Please check your email for the login link.
              </p>
            </div>
          )}
        </div>

        <Link 
          to="/signin" 
          className="text-gray-400 text-sm mt-6 inline-block hover:text-white transition-colors"
        >
          uncover your MS copilot-studio
        </Link>
      </div>
    </PageContainer>
  );
}

export default Home; 