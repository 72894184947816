import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import Home from './pages/Home';
import Uncover from './pages/Uncover';
import SignIn from './pages/SignIn';
import Terms from './pages/Terms';
import EmailConfirmation from './pages/EmailConfirmation';
import Report from './pages/Report';
import EmailVerification from './pages/EmailVerification';
import Flowise from './pages/Flowise';
import LogRocket from 'logrocket';

LogRocket.init('uxyp45/uncover-agent');

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Uncover />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/email-confirmation" element={<EmailConfirmation />} />
        <Route path="/verify-email" element={<EmailVerification />} />
        <Route path="/report" element={<Report />} />
        <Route path="/flowise" element={<Flowise />} />
      </Routes>
    </Router>
  );
}

export default App; 