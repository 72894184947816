import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

const PageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;
  overflow: hidden;
  background: linear-gradient(45deg, #1a237e, #0d47a1);
`;

function SignIn() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState('');
  const [resetSent, setResetSent] = useState(false);
  const [showResetForm, setShowResetForm] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setError('');
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    setError('');

    try {
      const result = await signInWithEmailAndPassword(auth, formData.email, formData.password);
      
      // Get user document directly using UID
      const userDocRef = doc(db, 'users', result.user.uid);
      const userDoc = await getDoc(userDocRef);
      
      if (!userDoc.exists()) {
        console.log('Creating new user document...');
        // Create user document if it doesn't exist
        await setDoc(userDocRef, {
          email: result.user.email,
          timestamp: new Date(),
          verified: true
        });
        console.log('User document created successfully', { userId: result.user.uid });
      } else {
        console.log('Existing user document found', { userId: result.user.uid });
      }

      navigate('/report');
    } catch (error) {
      console.error('Error signing in:', {
        error: error.message,
        code: error.code,
        email: formData.email
      });
      if (error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found') {
        setError('Invalid email or password');
      } else {
        setError('Failed to sign in. Please try again.');
      }
    }
    setIsProcessing(false);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (!formData.email) {
      setError('Please enter your email address');
      return;
    }
    setIsProcessing(true);
    try {
      await sendPasswordResetEmail(auth, formData.email);
      setResetSent(true);
    } catch (error) {
      console.error('Error sending reset email:', error);
      setError('Failed to send reset email. Please try again.');
    }
    setIsProcessing(false);
  };

  if (resetSent) {
    return (
      <PageContainer>
        <div className="w-full max-w-md bg-white/95 rounded-xl shadow-lg p-8 backdrop-blur-md">
          <div className="text-center">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Check Your Email</h2>
            <p className="text-gray-600 mb-6">
              We've sent password reset instructions to:
              <br />
              <span className="font-medium text-blue-600">{formData.email}</span>
            </p>
            <button
              onClick={() => {
                setResetSent(false);
                setShowResetForm(false);
              }}
              className="text-blue-600 hover:text-blue-800 font-medium"
            >
              Back to Sign In
            </button>
          </div>
        </div>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <div className="w-full max-w-md bg-white/95 rounded-xl shadow-lg p-8 backdrop-blur-md">
        <div className="text-center mb-8">
          <Link to="/" className="inline-block">
            <h1 className="text-3xl font-bold text-blue-600">Uncover Agent</h1>
          </Link>
          <p className="text-gray-600 mt-2">Sign in to view your agent reports</p>
        </div>

        <form onSubmit={showResetForm ? handleResetPassword : handleSignIn} className="space-y-6">
          <div>
            <label 
              htmlFor="email" 
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Email address
            </label>
            <input
              id="email"
              name="email"
              type="email"
              required
              value={formData.email}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="your@email.com"
            />
          </div>

          {!showResetForm && (
            <div>
              <label 
                htmlFor="password" 
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                required
                value={formData.password}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter your password"
              />
            </div>
          )}

          {error && (
            <p className="text-red-600 text-sm">{error}</p>
          )}

          <button
            type="submit"
            disabled={isProcessing}
            className={`w-full py-3 px-4 rounded-md text-white font-medium
              ${isProcessing 
                ? 'bg-blue-400 cursor-not-allowed' 
                : 'bg-blue-600 hover:bg-blue-700'
              } transition-colors`}
          >
            {isProcessing 
              ? 'Processing...' 
              : showResetForm 
                ? 'Send Reset Link'
                : 'Sign In'
            }
          </button>

          <div className="text-center space-y-4">
            {!showResetForm ? (
              <button
                type="button"
                onClick={() => setShowResetForm(true)}
                className="text-blue-600 hover:text-blue-800 text-sm font-medium"
              >
                Forgot your password?
              </button>
            ) : (
              <button
                type="button"
                onClick={() => setShowResetForm(false)}
                className="text-blue-600 hover:text-blue-800 text-sm font-medium"
              >
                Back to Sign In
              </button>
            )}
            
          </div>
        </form>
      </div>
    </PageContainer>
  );
}

export default SignIn; 