import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #2563eb;
`;

const Section = styled.section`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #1f2937;
`;

const Text = styled.p`
  line-height: 1.6;
  color: #4b5563;
  margin-bottom: 1rem;
`;

const List = styled.ul`
  list-style-type: disc;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
  color: #4b5563;
  line-height: 1.6;
`;

const ListItem = styled.li`
  margin-bottom: 0.5rem;
`;

const BackButton = styled(Link)`
  display: inline-block;
  margin-top: 2rem;
  color: #2563eb;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

function Terms() {
  return (
    <Container>
      <Title>Terms of Service</Title>
      
      <Section>
        <SectionTitle>1. Acceptance of Terms</SectionTitle>
        <Text>
          By accessing and using Uncover Agent ("Service"), you acknowledge that you have read, understood, and agree to be bound by these Terms of Service ("Terms") and our Privacy Policy. If you do not agree to these Terms, please do not use our Service.
        </Text>
      </Section>
      
      <Section>
        <SectionTitle>2. Service Description and Limitations</SectionTitle>
        <Text>
          Our Service provides external analysis and reporting of Microsoft Copilot Studio agents and other AI platforms. The Service performs scans from external access points and public endpoints only. You explicitly acknowledge and understand that:
        </Text>
        <List>
          <ListItem>The scan is performed from outside your organization's network and infrastructure.</ListItem>
          <ListItem>We cannot and do not guarantee the discovery of all agents, vulnerabilities, or security risks within your organization.</ListItem>
          <ListItem>The Service may not detect internally deployed or private agents that are not accessible from public endpoints.</ListItem>
          <ListItem>The accuracy and completeness of our scans are limited to publicly available information and access points.</ListItem>
        </List>
      </Section>

      <Section>
        <SectionTitle>3. Disclaimer of Warranties</SectionTitle>
        <Text>
          THE SERVICE IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT ANY WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE EXPLICITLY DISCLAIM:
        </Text>
        <List>
          <ListItem>Any warranties regarding the security, reliability, timeliness, and performance of the Service.</ListItem>
          <ListItem>Any warranties regarding the completeness, accuracy, or reliability of scan results.</ListItem>
          <ListItem>Any warranties that the Service will meet your specific requirements or expectations.</ListItem>
          <ListItem>Any warranties regarding the detection of all potential security vulnerabilities or risks.</ListItem>
        </List>
      </Section>

      <Section>
        <SectionTitle>4. Limitation of Liability</SectionTitle>
        <Text>
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, UNCOVER AGENT AND ITS OFFICERS, EMPLOYEES, AGENTS, PARTNERS, AND LICENSORS SHALL NOT BE LIABLE FOR ANY:
        </Text>
        <List>
          <ListItem>Direct, indirect, incidental, special, consequential, or exemplary damages.</ListItem>
          <ListItem>Loss of profits, data, use, goodwill, or other intangible losses.</ListItem>
          <ListItem>Damages resulting from security breaches or vulnerabilities, whether or not they were detected by our Service.</ListItem>
          <ListItem>Damages arising from your use or inability to use the Service.</ListItem>
          <ListItem>Damages resulting from any unauthorized access to your agents or systems.</ListItem>
        </List>
      </Section>

      <Section>
        <SectionTitle>5. Data Collection and Storage</SectionTitle>
        <Text>
          By using our Service, you grant us permission to:
        </Text>
        <List>
          <ListItem>Collect and store information about your publicly accessible AI agents and their configurations.</ListItem>
          <ListItem>Retain scan results and related data for analysis, service improvement, and historical reference.</ListItem>
          <ListItem>Use aggregated and anonymized data for research and development purposes.</ListItem>
        </List>
        <Text>
          We implement reasonable security measures to protect your data but cannot guarantee absolute security. You acknowledge that data transmission over the internet is inherently insecure and we cannot ensure the security of data transmitted to our Service.
        </Text>
      </Section>

      <Section>
        <SectionTitle>6. Indemnification</SectionTitle>
        <Text>
          You agree to indemnify, defend, and hold harmless Uncover Agent and its officers, directors, employees, agents, and licensors from and against any claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising from:
        </Text>
        <List>
          <ListItem>Your use of the Service.</ListItem>
          <ListItem>Your violation of these Terms.</ListItem>
          <ListItem>Your violation of any rights of a third party.</ListItem>
          <ListItem>Your violation of any applicable laws, rules, or regulations.</ListItem>
        </List>
      </Section>

      <Section>
        <SectionTitle>7. Modifications to Service and Terms</SectionTitle>
        <Text>
          We reserve the right to modify or discontinue, temporarily or permanently, the Service or any features or portions thereof without prior notice. We may also update these Terms from time to time. Your continued use of the Service following the posting of any changes constitutes acceptance of those changes.
        </Text>
      </Section>

      <Section>
        <SectionTitle>8. Governing Law</SectionTitle>
        <Text>
          These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which Uncover Agent is established, without regard to its conflict of law provisions. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts in that jurisdiction.
        </Text>
      </Section>
      
      <BackButton to="/">← Back to Home</BackButton>
    </Container>
  );
}

export default Terms; 